.MuiButton-label {
  font-weight: 600;
  font-size: 18px;
}

button.emptyBtn {
  border-color: #9de3cd;
  border-width: 1.5px;
  color: #9de3cd;
  height: 60px;
}

button.emptyBtn:hover {
  background-color: #e0f2eb;
  border-color: #73c7ad;
  color: #73c7ad;
}

button.emptySmaller {
  height: 25px;
}

button.emptySmaller span {
  font-size: 15px;
  height: 12px;
}

button.modalCloseBtn {
  position: absolute;
  right: 0;
}

button.filledBtn {
  background-color: #9de3cd;
  height: 60px;
  color: white;
}

button.filledBtn:hover {
  background-color: #73c7ad;
}

button.deleteBtn {
  background-color: white;
  color: #ff9e72;
  font-weight: 600;
  height: 60px;
}

button.deleteBtn:hover {
  color: #ee4d4d;
  background-color: white;
}

button.sideBtn {
  position: fixed;
  bottom: 10em;
  margin-right: -20px;
  right: 0;
  height: 2.2em;
  padding-top: 0;
  border-color: #9de3cd;
  border-bottom: none;
  border-radius: 1px 0 0 1px;
  border-width: 1.5px;
  color: #9de3cd;
  font-weight: 600;
  transform: rotate(-90deg);
  background-color: rgb(255, 255, 255, 0.8);
}

button.sideBtn:hover {
  background-color: #e0f2eb;
  border-color: #73c7ad;
  color: #73c7ad;
}

button.filledBtn.createRoleBtn {
  font-size: 20px;
  height: 20px;
}

button.deleteBtn.deleteRoleBtn {
  background-color: #ff9e72;
  color: white;
  font-size: 20px;
  height: 20px;
  vertical-align: middle;
  margin-bottom: 2px;
}

button.deleteBtn.deleteRoleBtn:disabled {
  background-color: #e5e5e5;
}

button.deleteBtn.deleteRoleBtn:hover {
  background-color: #ee4d4d;
}

button.googleButton {
  background-color: #4285f4;
  border: none;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  line-height: 48px;
  display: block;
  border-radius: 4px;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.googleButton:hover {
  filter: opacity(90%);
}

.backButton {
  text-align: left;
}

button.accountNameButton {
  position: absolute;
  right: 0;
  color: white;
  text-transform: none;
  font-size: 16px;
}

button.manageRolesBtn {
  margin-bottom: 10px;
}

button.noAcctBtn {
  color: #6d6e70;
}

button.signOutBtn {
  background-color: transparent;
  border: none;
  font-size: 0.9rem;
  font-weight: 400;
  padding: 6px 0 6px 0;
  color: #6d6e70;
}

.signOutBtn:hover {
  color: #73c7ad;
  cursor: pointer;
}
