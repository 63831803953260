div dl dd svg {
  position: relative;
  bottom: 2px;
}

div.MuiTabs-root {
  background-color: #cff1e5;
}

.MuiTab-wrapper {
  font-size: 18px;
  color: #3ba1bd;
}

td.deviceCell {
  border-bottom: none;
  width: 50%;
  vertical-align: top;
}

dd.softBrickStatus {
  color: red;
  font-weight: bold;
}

.deviceTable {
  table-layout: fixed;
}

.gridItem {
  padding: 16px;
}

div.sectionTitleContainer {
  background-color: transparent;
  padding-left: 16px;
}

h1.sectionTitle {
  font-size: 26px;
  padding: 16px 16px 16px 0;
  text-align: start;
}

td h1 {
  font-size: 26px;
  float: left;
}

td h2 {
  font-size: 24px;
}

td.associatedCells h2 {
  float: left;
}

.associatedServiceUsersNumber {
  color: #ee4d4d;
}
