li {
  list-style-type: none;
}

dt {
  font-weight: 700;
  display: block;
}

.accDefList {
  font-size: 22px;
  margin-left: 0;
}

.accDefList dd {
  margin-left: 0;
  margin-bottom: 0.5rem;
}

.defList {
  display: flex;
  flex-flow: row wrap;
  padding: 1em;
  font-size: 18px;
}

.defList > dt {
  flex-basis: 45%;
  padding: 2px 4px;
  text-align: left;
}

.defList > dt::after {
  content: ':';
}

.defList > dd {
  flex-basis: 55%;
  flex-grow: 1;
  margin: 0;
  padding: 2px 4px;
  text-align: right;
}

.deviceDefList {
  display: flex;
  flex-flow: column wrap;
  max-height: 5em;
  margin: 1em;
  width: 100%;
}

.deviceDefList > dt {
  text-decoration: underline;
}

.deviceDefList > dd {
  margin: 0;
  min-height: 3em;
}

.defList div dt {
  text-decoration: underline;
}
