ul.MuiList-root.MuiList-padding {
  font-weight: 300;
}

ul.MuiList-root.MuiList-padding > div h2 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
}

ul.MuiList-root.MuiList-padding > div h3 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
}

button.sideBtn.MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
  z-index: 100;
}

div.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
  width: 32em;
  font-size: 15px;
  font-weight: 600;
  padding: 10px;
}
