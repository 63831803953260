html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

html * {
  box-sizing: inherit;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

.crumbStyle {
  font-size: 15px;
  color: #6d6e70;
  padding: 5px;
  font-weight: 600;
}

.crumbStyle:hover {
  opacity: 80%;
}

.finalCrumb {
  font-size: 15px;
  color: #73c7ad;
}

svg.MuiSvgIcon-root.crumbSeparator {
  font-size: 1.25rem;
  vertical-align: middle;
}

a {
  text-decoration: none;
  background-color: transparent;
}
