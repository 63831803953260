img.bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Full page background color */
div.landing-bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 50%;
  left: 0;
  z-index: -1;
  background-color: white;
}

@media screen and (max-width: 1024px) {
  /* Specific to this particular image */
  img.bg {
    left: 50%;
    margin-left: -512px; /* 50% */
  }

  div.landing-bg {
    left: 50%;
    margin-left: -512px; /* 50% */
  }
}

.landingCardContainer {
  height: 100vh;
  padding: 20px;
}

div.MuiPaper-root.MuiPaper-elevation1.MuiCard-root.MuiPaper-rounded.cardId {
  text-align: center;
  width: 37.5em;
  height: auto;
  padding-top: 30px;
  padding-bottom: 50px;
  color: #6d6e70;
}
