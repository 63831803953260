.nav-link {
  color: white;
  font-size: 18px;
}

.nav-link:hover {
  color: #f2f2f2;
}

svg.MuiSvgIcon-root.navIcon {
  font-size: 300%;
  color: #9de3cd;
}

svg.MuiSvgIcon-root.navIcon:hover {
  color: #73c7ad;
}

svg.MuiSvgIcon-root.menuIcon {
  font-size: 150%;
  color: white;
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar {
  font-size: 18px;
}

.MuiTypography-root.MuiTypography-caption {
  font-size: 18px;
  width: 14rem;
  margin-left: -45px;
}

.MuiSvgIcon-root.MuiSelect-icon.MuiTablePagination-selectIcon {
  font-size: 30px;
}

.MuiSelect-root.MuiSelect-select.MuiTablePagination-select {
  color: #6d6e70;
}

div.MuiInput-underline::after {
  border-bottom: 2px solid  #9de3cd;
}

ul.MuiList-root.MuiList-padding {
  color: #6d6e70;
}

div#root header div.mainDiv {
  display: flex;
}

div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 > h2 {
  text-align: left;
}

.banner {
  margin-left: -55px;
  margin-right: 15px;
  height: 60px;
  vertical-align: middle;
}

.banner .icon {
  display: none;
}

details {
  white-space: pre-wrap;
}

.errorBoundaryContainer {
  text-align: center;
}

.errorBoundaryContainer p {
  font-size: 18px;
  color: #ee4d4d;
}

.errorBoundaryContainer .owlIcon {
  height: 60px;
  margin: 0 0 0 160px;
}

.errorBoundaryContainer .owlIcon .icon {
  fill: #9de3cd;
}
