.formWithValidation {
  max-width: 500px;
  margin: 0 auto;
}

.errorMessages {
  color: #bf1650;
}

.errorMessages::before {
  display: inline;
  content: '⚠ ';
}

.fieldContainer {
  display: flex;
  flex-direction: column;
}
