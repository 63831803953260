label.MuiFormLabel-root.Mui-focused {
  color: #73c7ad;
  text-shadow: 1px 1px lightgray;
}

div .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ff9e72;
}

div .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #ff9e72;
}

svg.searchIcon {
  color: white;
  font-size: 30px;
}

button.MuiIconButton-root.searchBtn {
  background-color: #ff9e72;
  height: 60px;
  width: 60px;
  border-radius: 4px;
  justify-content: center;
}

dl.defList {
  font-size: 15px;
}

button.filledBtn.viewDetails {
  background-color: #ff9e72;
  display: block;
  width: 200px;
  margin: 0 auto;
}

button.filledBtn.disabled {
  background-color: #c0c0c0;
  display: block;
  width: 200px;
  margin: 0 auto;
}

a.links span {
  color: white;
}

button.filledBtn.disabled span {
  font-size: 15px;
}

button.filledBtn.viewDetails:hover {
  background-color: #fab793;
}
